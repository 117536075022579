import firebase from "firebase/app";
import { db } from "@/firebase/firebaseConfig";

import { getMangoFields } from "../common/mango-custom-fields.js";
import { getFundGrubeFields } from "../common/fund-grube-custom-fields.js";

import recoveredProducts from "../data/cases/lists/recoveredProducts.json";
import witnessTypes from "@/data/cases/lists/witnessTypes.json";

import bandsFormConfig from "@/helpers/bandsFormConfig";
import criminalsFormConfig from "@/helpers/criminalsFormConfig";
import vehiclesFormConfig from "@/helpers/vehiclesFormConfig";

const WITNESS_TYPE_VIGILANT_ID = "GfTXTyowoGqR5UStPm0I";

const createFormConfig = (self, role) => {
  let fields1 = [];
  let fields2 = [];
  let fields3 = [];

  fields1.push({
    size: "1/3",
    type: "select",
    attribute: "customer",
    validation: "required",
    extra: {
      optionsFunction: () => {
        return self.allCustomers.filter((c) =>
          c.casesTypes.map((c) => c.id).includes("penal")
        );
      },
    },
    readonly: role === "customer" || role === "supercustomer",
  });
  fields1.push({
    size: "1/3",
    type: "select",
    attribute: "establishment",
    validation: "required",
    extra: {
      optionsFunction: (data) => {
        return self.allEstablishments
          .filter(
            (x) => data && data.customer && x.customer.id === data.customer.id
          )
          .map((x) => {
            return x.id === "empresa"
              ? {
                  ...x,
                  id: x.id,
                  alias: self.$t("establishments.labels.wholeCompany"),
                }
              : x;
          });
      },
    },
    disabledFunction(data) {
      return !data.customer;
    },
  });

  fields2.push({
    size: "1/3",
    type: "datetime",
    attribute: "datetime",
    validation: "required",
  });

  fields2.push({
    size: "1/1",
    type: "text",
    attribute: "facts",
    validation: "required",
  });

  fields3.push({
    size: "1/2",
    type: "boolean",
    attribute: "ticket",
    validation: "required",
  });
  fields3.push({
    size: "1/2",
    type: "number",
    attribute: "ticketAmount",
    validation: "required",
    visibility(data) {
      return data.ticket;
    },
  });

  fields3.push({
    size: "1/2",
    type: "enum",
    attribute: "recoveredProducts",
    validation: "required",
    extra: {
      enumType: "recoveredProducts",
      options: recoveredProducts,
      customSort: () => 1, //Orden establecido por nosotros
    },
  });

  let fields4 = [].concat(...getMangoFields({}), ...getFundGrubeFields({}));

  return [fields1, fields2, fields3, fields4].map((attributes) => ({
    attributes,
  }));
};

const editGeneralTab = (self, role) => {
  let fields = [];

  let readonly = ["customer", "supercustomer"].includes(role);

  fields.push({
    size: "1/2",
    type: "datetime",
    attribute: "datetime",
    validation: "required",
    readonly,
  });

  fields.push({
    size: "1/2",
    type: "select",
    attribute: "lawyer",
    validation: "required",
    extra: {
      optionsFunction: () => {
        return self.allLawyers;
      },
    },
    readonly,
  });

  fields.push({
    size: "1/2",
    type: "select",
    attribute: "customer",
    validation: "required",
    extra: {
      optionsFunction: () => {
        return self.allCustomers.filter((c) =>
          c.casesTypes.map((c) => c.id).includes("penal")
        );
      },
    },
    readonly: true,
  });
  fields.push({
    size: "1/2",
    type: "select",
    attribute: "establishment",
    validation: "required",
    extra: {
      optionsFunction: (data) => {
        return self.allEstablishments
          .filter(
            (x) => data && data.customer && x.customer.id === data.customer.id
          )
          .map((x) => {
            return x.id === "empresa"
              ? {
                  ...x,
                  id: x.id,
                  alias: self.$t("establishments.labels.wholeCompany"),
                }
              : x;
          });
      },
    },
    disabledFunction(data) {
      return !data.customer;
    },
    readonly,
  });

  fields.push({
    size: "1/1",
    type: "text",
    attribute: "facts",
    validation: "required",
    readonly: ["customer"].includes(role),
  });

  fields.push({
    size: "1/2",
    type: "boolean",
    attribute: "ticket",
    validation: "required",
    readonly,
  });
  fields.push({
    size: "1/2",
    type: "number",
    attribute: "ticketAmount",
    validation: "required",
    visibility(data) {
      return data.ticket;
    },
    readonly,
  });

  fields.push({
    size: "1/2",
    type: "enum",
    attribute: "recoveredProducts",
    validation: "required",
    extra: {
      enumType: "recoveredProducts",
      options: recoveredProducts,
      customSort: () => 1, //Orden establecido por nosotros
    },
    readonly,
  });

  const mangoFields = getMangoFields({}).map((b) =>
    b.map((x) => ({ ...x, readonly: x.readonly || readonly }))
  );
  fields = [].concat(...fields, ...mangoFields);

  fields.push({
    size: "1/3",
    type: "separator",
  });

  const fundGrubeFields = getFundGrubeFields({}).map((b) =>
    b.map((x) => ({ ...x, readonly: x.readonly || readonly }))
  );
  fields = [].concat(...fields, ...fundGrubeFields);

  fields.push({
    size: "1/3",
    type: "boolean",
    attribute: "preimplantation",
    validation: "required",
    readonly,
  });

  return [fields, ...editTableFields(self, role)].map((attributes) => ({
    attributes,
  }));
};

const editTableFields = (self, role) => {
  const formConfigs = {
    Criminal: criminalsFormConfig,
    Band: bandsFormConfig,
    Vehicle: vehiclesFormConfig,
  };
  const config = [
    {
      singleName: "Criminal",
      uniqueKey: "dni",
      attributes: ["dni", "name", "surname", "nickname"],
      title: (x) => `${x.name} ${x.surname} ${x.dni ? `(${x.dni})` : ""}`,
    },
    {
      singleName: "Band",
      uniqueKey: "alias",
      attributes: ["alias"],
      title: (x) => `${x.alias}`,
    },
    {
      singleName: "Vehicle",
      uniqueKey: "plate",
      attributes: ["plate", "brand", "model", "color"],
      title: (x) => `${x.brand} - ${x.model} (${x.plate})`,
    },
  ];
  const tableFields = config.map(
    ({ singleName, uniqueKey, attributes, title }) => {
      let name = `${singleName}s`;
      let collectionName = name.toLowerCase();
      let fields = formConfigs[singleName];
      let columns = [];
      attributes.forEach((a) => {
        columns.push({
          headerKey: `${collectionName}.fields.${a}`,
          filter: false,
          width: 100,
          field: a,
        });
      });

      return [
        {
          size: "1/1",
          type: "list",
          extra: listConfig(
            self,
            role,
            name,
            collectionName,
            title,
            uniqueKey,
            fields,
            columns
          ),
        },
      ];
    }
  );
  return tableFields;
};

const listConfig = (
  self,
  role,
  name,
  collectionName,
  title,
  uniqueKey,
  fields,
  columns
) => {
  let onAdd = async (id) => {
    let relationToAdd = { issueId: self.issue.id, entityId: id };
    await db.collection(`issues${name}`).add(relationToAdd);
  };
  let onRemove = async (id) => {
    await db
      .collection(`issues${name}`)
      .where("entityId", "==", id)
      .get()
      .then((x) => x.docs.forEach((y) => y.ref.delete()));
  };
  let filterOptions = () => {
    return (self[`notIssue${name}`] || []).map((e) => ({
      title: title(e),
      ...e,
    }));
  };
  let data = () => {
    return self[`issue${name}`];
  };
  let prevalidate = async (data, collection, errors) => {
    if (data[uniqueKey]) {
      let snapshot = await collection
        .where(uniqueKey, "==", data[uniqueKey])
        .get();

      if (snapshot.docs && snapshot.docs.length > 0) {
        errors.add({
          field: uniqueKey,
          msg: self.$t(`Validations.${collectionName}.${uniqueKey}.unique`),
        });
        return true;
      }
    }

    if (uniqueKey === "plate") {
      if (!/^[0-9]{4}[A-Z]{3}$/.test(data.plate)) {
        errors.add({
          field: "plate",
          msg: self.$t(`Validations.${collectionName}.plate.invalid`),
        });
        return true;
      }
    }

    return false;
  };

  if (!["supercustomer", "customer"].includes(role)) {
    columns.push({
      headerName: "",
      filter: false,
      width: 50,
      field: "id",
      cellRendererFramework: "CellRendererRemove",
      onRemove,
    });
  }

  let filters = [];
  if (!["supercustomer", "customer"].includes(role)) {
    filters.push({
      size: "1/5",
      mobileSize: "1/2",
      select: true,
      placeholder: `cases.placeholders.${collectionName}`,
      label: "title",
      clearable: false,
      resetOnOptionsChange: true,
      onChange: (e) => {
        if (e) {
          //Cuando reseteamos nos envía null
          onAdd(e.id);
        }
      },
      options: filterOptions,
    });
    filters.push({
      size: "4/5",
      mobileSize: "1/2",
      sidebar: true,
      i18n: `${collectionName}.buttons.new`,
      extraClasses: "text-right",
      sidebarConfig: {
        title: `${collectionName}.buttons.new`,
        collectionName: collectionName,
        attributes: fields,
        prevalidate,
        afterCreate: onAdd,
      },
    });
  }

  return {
    data,
    filters,
    gridConfig: {
      components: {},
      gridOptions: {},
      columnDefs: columns,
      defaultColDef: {
        sortable: true,
        resizable: true,
      },
      onRowClicked: () => {},
    },
  };
};

const editFilesTab = (self) => {
  let fields1 = [];
  fields1.push({
    size: "1/1",
    type: "alert",
    color: "danger",
    icon: "warning",
    hideLabel: true,
    text: self.$t("messages.errors.noScannedTicket"),
    visibility(model) {
      return model.ticket && !(self.files || []).length;
    },
  });
  fields1.push({
    size: "1/1",
    type: "files",
    attribute: "issuesFiles",
    hideLabel: true,
    extra: {
      files: () => {
        return self.files;
      },
      config: {
        url: "/",
        uploadMultiple: true,
        parallelUploads: 5,
        method: "put",
        autoQueue: false,
        maxFilesize: 2048,
        dictDefaultMessage: self.$t("messages.filesDragAnddrop"),
      },
    },
  });

  return [fields1].map((attributes) => ({ attributes }));
};

const editWitnessesTab = (self, role) => {
  let fields1 = [];

  let attributes = [];

  attributes.push({
    type: "input",
    validation: "required|alpha_spaces",
    attribute: "name",
  });
  attributes.push({
    type: "input",
    validation: "required|alpha_spaces",
    attribute: "lastname",
  });
  attributes.push({ type: "input", attribute: "code" });
  attributes.push({ type: "input", validation: "email", attribute: "email" });
  attributes.push({
    type: "input",
    validation: "digits:9",
    attribute: "mobile",
  });
  attributes.push({
    type: "enum",
    validation: "required",
    attribute: "witnessType",
    extra: {
      enumType: "witnessTypes",
      options: witnessTypes,
      customSort(a, b) {
        return a.order - b.order;
      },
    },
  });
  attributes.push({
    type: "input",
    attribute: "nTip",
    visibility(model) {
      return (
        model.witnessType && model.witnessType.id === WITNESS_TYPE_VIGILANT_ID
      );
    },
  });

  let collectionName = "witnesses";

  let filters = [];
  filters.push({
    size: "1/2",
    mobileSize: "1/1",
    custom: true,
    template: "witnessSearcher",
    data: {},
  });
  filters.push({
    size: "1/2",
    mobileSize: "1/1",
    sidebar: true,
    i18n: `${collectionName}.buttons.new`,
    extraClasses: "text-right",
    sidebarConfig: {
      title: `${collectionName}.buttons.new`,
      collectionName,
      attributes: attributes,
      prevalidate: async (data, collection, errors) => {
        if (data.code) {
          let snapshot = await collection
            .where("code", "==", data.code)
            .where("customer.id", "==", self.issue.customer.id)
            .get();
          if (snapshot.docs && snapshot.docs.length > 0) {
            errors.add({
              field: "code",
              msg: self.$t(`${collectionName}.validations.code.unique`),
            });
            return true;
          }
        }

        return false;
      },
      afterCreate: (id) => {
        db.collection(collectionName)
          .doc(id)
          .update({
            issues: firebase.firestore.FieldValue.arrayUnion(self.issue.id),
            customer: self.issue.customer,
          });
      },
    },
  });

  let columnDefs = [
    {
      headerName: self.$t(`${collectionName}.columns.code`),
      field: "code",
      filter: false,
      width: 75,
    },
    {
      headerName: self.$t(`${collectionName}.columns.name`),
      field: "name",
      filter: false,
      width: 150,
    },
    {
      headerName: self.$t(`${collectionName}.columns.lastname`),
      field: "lastname",
      cellRenderer: (params) => {
        if (params.data.nTip) {
          return params.data.lastname + ` (${params.data.nTip})`;
        } else {
          return params.data.lastname;
        }
      },
      filter: false,
      width: 150,
    },
    {
      headerName: self.$t(`${collectionName}.columns.email`),
      field: "email",
      filter: false,
      width: 150,
    },
    {
      headerName: self.$t(`${collectionName}.columns.mobile`),
      field: "mobile",
      filter: false,
      width: 75,
    },
    {
      headerName: self.$t(`${collectionName}.columns.injuried`),
      filter: false,
      width: 75,
      cellClass: "text-center",
      cellRenderer: (params) => {
        var injuriedValues = params.data.injuried || [];
        var isChecked = !!injuriedValues.find((i) => i === self.entity.id);
        if (["supercustomer", "customer"].includes(role)) {
          return self.$t(`boolean.${isChecked ? "yes" : "no"}`);
        } else {
          var input = document.createElement("input");
          input.type = "checkbox";
          input.checked = isChecked;
          input.addEventListener("click", function () {
            db.collection("witnesses")
              .doc(params.data.id)
              .update({
                injuried: isChecked
                  ? firebase.firestore.FieldValue.arrayRemove(self.entity.id)
                  : firebase.firestore.FieldValue.arrayUnion(self.entity.id),
              });
          });
          return input;
        }
      },
    },
  ];

  fields1.push({
    size: "1/1",
    type: "list",
    extra: {
      data: () => {
        return self.witnesses;
      },
      filters,
      gridConfig: {
        components: {},
        gridOptions: {},
        columnDefs,
        defaultColDef: {
          sortable: true,
          resizable: true,
        },
        onRowClicked: () => {},
      },
    },
  });

  return [fields1].map((attributes) => ({ attributes }));
};

const editFormConfig = (self, role) => {
  let generalRows = editGeneralTab(self, role);
  let witnessesRows = editWitnessesTab(self, role);
  let fileRows = editFilesTab(self);

  return [
    { name: "general", rows: generalRows },
    { name: "witnesses", rows: witnessesRows },
    { name: "files", rows: fileRows },
  ];
};

let formConfig = (self, role, isForCreate) => {
  if (isForCreate) {
    return createFormConfig(self, role);
  } else {
    return editFormConfig(self, role);
  }
};

export default formConfig;
