<template>
    <IssuesNewCustomerDependent v-if="useNewVersion"/>
    <IssuesNew v-else/>
</template>
<script>
import IssuesNew from "./IssuesNew.vue";
import IssuesNewCustomerDependent from "./IssuesNewCustomerDependent.vue";

export default {
    components: {
        IssuesNew,
        IssuesNewCustomerDependent
    },
    computed: {
        useNewVersion() {
            //TODO: ESTO POR AHORA ESTA HECHO PARA DECATHLON... HACER MÁS VERSATIL
            return this.$store.state.AppActiveUser.customer && this.$store.state.AppActiveUser.customer.alias.trim() === "DECATHLON";
        }
    }
}
</script>
