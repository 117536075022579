<template>
  <FormPage
    :collectionName="collectionName"
    :tabs="tabs"
    :prevalidate="prevalidate"
    :defaultData="defaultData"
    pathPrefix="main"
  />
</template>
<script>
import { mapGetters } from "vuex";

import FormPage from "@/components/penal-retail-helpers/FormPage";
import formConfig from "@/helpers/issuesFormConfig";

let collectionName = "issues";

export default {
  components: {
    FormPage,
  },
  computed: {
    ...mapGetters(["allCustomers", "allEstablishments", "allLawyers"]),
    isCustomer() {
      return this.$store.state.AppActiveUser.role === "customer";
    },
    isSuperCustomer() {
      return this.$store.state.AppActiveUser.role === "supercustomer";
    },
    defaultData() {
      let defaultCustomer =
        this.isCustomer || this.isSuperCustomer
          ? this.$store.state.AppActiveUser.customer
          : undefined;
      let defaultEstablishment =
        this.isCustomer &&
        this.$store.state.AppActiveUser.establishments.length === 1
          ? this.allEstablishments
              .map((x) => ({ id: x.id, alias: x.alias }))
              .find(
                (x) =>
                  x.id === this.$store.state.AppActiveUser.establishments[0]
              )
          : undefined;

      return {
        customer: defaultCustomer,
        establishment: defaultEstablishment,
      };
    },
  },
  data() {
    let rows = formConfig(this, this.$store.state.AppActiveUser.role, true);

    return {
      collectionName,
      tabs: [{ name: `${collectionName}.tabs.general`, rows }],
    };
  },
  methods: {
    prevalidate() {
      return false;
    },
  },
};
</script>
