<template>
  <div class="custom-absolute-loading" v-if="!isReady">
    <div class="loading">
      <div class="effect-1 effects"></div>
      <div class="effect-2 effects"></div>
      <div class="effect-3 effects"></div>
    </div>
  </div>

  <FormPage
    :collectionName="collectionName"
    :tabs="tabs"
    :prevalidate="prevalidate"
    :defaultData="defaultData"
    pathPrefix="main"
    v-else
  />
</template>
<script>
import { db } from "@/firebase/firebaseConfig";
import firebase from "firebase/app";

import FormPage from "@/components/penal-retail-helpers/FormPage";

import recoveredProducts from "@/data/cases/lists/recoveredProducts.json";

let collectionName = "issues";

export default {
  components: {
    FormPage,
  },
  watch: {
    customer() {},
  },
  computed: {
    isCustomer() {
      return this.$store.state.AppActiveUser.role === "customer";
    },
    isSuperCustomer() {
      return this.$store.state.AppActiveUser.role === "supercustomer";
    },
    isReady() {
      return !!this.customers && !!this.establishments;
    },
    defaultData() {
      let defaultCustomer =
        this.isCustomer || this.isSuperCustomer
          ? this.$store.state.AppActiveUser.customer
          : undefined;
      let defaultEstablishment =
        this.isCustomer &&
        this.$store.state.AppActiveUser.establishments.length === 1
          ? this.establishments
              .map((x) => ({ id: x.id, alias: x.alias }))
              .find(
                (x) =>
                  x.id === this.$store.state.AppActiveUser.establishments[0]
              )
          : undefined;

      return {
        customer: defaultCustomer,
        establishment: defaultEstablishment,
      };
    },

    formRows() {
      //TODO: ESTO POR AHORA ESTA HECHO PARA DECATHLON... HACER MÁS VERSATIL
      const role = this.$store.state.AppActiveUser.role;
      let fields1 = [];
      let fields2 = [];
      let fields3 = [];
      let fields4 = [];

      fields1.push({
        size: "1/3",
        type: "datetime",
        attribute: "datetime",
        validation: "required",
      });
      fields1.push({
        size: "1/3",
        type: "select",
        attribute: "customer",
        validation: "required",
        extra: {
          optionsFunction: () => {
            return this.customers
              ? this.customers.filter((c) =>
                  c.casesTypes.map((c) => c.id).includes("penal")
                )
              : [];
          },
        },
        readonly: role === "customer" || role === "supercustomer",
      });
      fields1.push({
        size: "1/3",
        type: "select",
        attribute: "establishment",
        validation: "required",
        extra: {
          optionsFunction: (data) => {
            return this.establishments
              ? this.establishments.filter(
                  (x) => x.customer.id === data.customer.id
                )
              : [];
          },
        },
        visibility(data) {
          return !!data.customer;
        },
      });

      fields2.push({
        size: "1/1",
        type: "text",
        attribute: "facts",
        validation: "required",
      });

      fields3.push({
        size: "1/3",
        type: "boolean",
        attribute: "ticket",
        validation: "required",
      });
      fields3.push({
        size: "1/3",
        type: "number",
        attribute: "ticketAmount",
        validation: "required",
        visibility(data) {
          return data.ticket;
        },
      });

      fields4.push({
        size: "1/3",
        type: "select",
        attribute: "recoveredProducts",
        validation: "required",
        extra: { options: recoveredProducts },
      });

      return [fields1, fields2, fields3, fields4].map((attributes) => ({
        attributes,
      }));
    },
    tabs() {
      return [{ name: `${collectionName}.tabs.general`, rows: this.formRows }];
    },
  },
  created() {
    if (this.isCustomer || this.isSuperCustomer) {
      let customerId = this.$store.state.AppActiveUser.customer.id;
      db.collection("customers")
        .doc(customerId)
        .get()
        .then((ref) => {
          this.customers = [{ id: ref.id, ...ref.data() }];
        });
      if (this.isSuperCustomer) {
        this.$bind(
          "establishments",
          db
            .collection("establishments")
            .where("customer.id", "==", customerId)
            .where("deleted", "==", false)
            .orderBy("alias"),
          { wait: true }
        );
      } else {
        this.$bind(
          "establishments",
          db
            .collection("establishments")
            .where("customer.id", "==", customerId)
            .where(
              firebase.firestore.FieldPath.documentId(),
              "in",
              this.$store.state.AppActiveUser.establishments
            )
            .where("deleted", "==", false),
          { wait: true }
        );
      }
    } else {
      this.$bind(
        "customers",
        db
          .collection("customers")
          .where("deleted", "==", false)
          .orderBy("alias"),
        { wait: true }
      );
      this.$bind(
        "establishments",
        db
          .collection("establishments")
          .where("deleted", "==", false)
          .orderBy("alias"),
        { wait: true }
      );
    }
  },
  data() {
    return {
      customers: null,
      establishments: null,
      courts: [],
      collectionName,
    };
  },
  methods: {
    prevalidate() {
      return false;
    },
  },
};
</script>
